body {
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
  background-color: #0F0F13;
  color: white;
  font-style: normal;
  -webkit-font-smoothing: antialiased; 
  
}

* {
  scroll-behavior: smooth;
  box-sizing: border-box;

}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}

span {
  color: #8649EF;
}

.sitenavigation {
  position: fixed;
  top: 0;
  left: 0; 
  width: 100%;
  padding: 2rem;
  background: #0F0F13;
  display: flex;
  justify-content: space-between; 
  z-index: 100;
}

.name {
  font-size: 1.7rem;
  margin-left: 0;
  font-weight: bold;
}

.navbar, .navbar a {
  box-sizing: border-box;
}


.sitenavigation a {
  text-decoration: none;
}

.navbar a {
  font-size: 1.7rem;
  color: white;
  margin-left: 4rem;
  transition: .3s;
}

.navbar a:hover,
.navbar a.active {
  color: #8649EF
}

#menu-icon {
  font-size: 3.6rem;
  color: white;
  display: none;
}

.home {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.links a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: #8649EF;
  border: .2rem solid #8649EF;
  border-radius: 50%;
  font-size: 2rem;
  color: #0F0F13;
  margin: 3rem 1.5rem 3rem 0;
  transition: .5s ease;
}

.links a:hover {
  background: #8649EF;
  color: white;
  box-shadow: 0 0 1rem #8649EF;
}

.education,
.experience,
.projects,
.contact {
  display: flex;
  align-items: center;
  gap: 2rem;
  z-index: 10;
}


.experience-content {
  width: 85%;
  z-index: 10;
}

.introduction {
  padding: -30rem 20% 2rem;
  margin-bottom: 300px;
  z-index: 10;
}


.education-content,
.experience-content,
.projects-content,
.contact-form,
.col {
  /* padding: -30rem 20% 2rem; */
  margin-bottom: 650px;
  z-index: 10;
}

.introduction h1 {
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.3;
}

.introduction h3 {
  font-size: 3rem;
  font-weight: 700;
  color: #8649EF
}

.introduction p {
  font-size: 1.6rem;
  line-height: 1.3;
}

h1,
p,
h3 {
  margin: 0;
  padding: 0;
}

.introduction h3:nth-of-type(2) {
  margin-bottom: 2rem;
}

.education h1,
.experience h1,
.col h1,
.projects h1 {
  font-size: 5rem;
  font-weight: 700;
  line-height: 1.3;
}

.contact h1 {
  font-size: 5rem;
  font-weight: 700;
  line-height: 1.3;
  display: flex;
  justify-content: center;
}

.contact {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  min-height: 100vh;       /* Take up at least the full viewport height */
}
.education-content p {
  font-size: 1.6rem;
  margin: 2rem 0 3rem;
}

/* Education */
.education-flex-container {
  font-size: 2.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.school-details {
  margin-right: 20px; /* Space between text and video */
}

.school-video {
  margin-left: 200px;
  width: 500px; /* Set the width of the video container */
}

.school-video iframe {
  width: 100%; /* Video fills the container */
  height: 350px; 
  border-radius: 8px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .education-flex-container {
    flex-direction: column; 
    font-size: 1.6rem; 
  }

  .school-details, .school-video {
    flex: none; 
    width: 100%; 
    margin-right: 0; /* Reset margins for smaller screens */
    margin-left: 0;
  }

  .school-video iframe {
    height: auto; /* Height adjusts to maintain aspect ratio */
  }
}


/* Project Cards */
.project-cards-container {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  gap: 20px; 
  padding: 20px;
}

/* Project Card Styles */
.project-card {
  justify-content: center;
  width: 100%; 
  box-shadow: 0 0 3px #1a191b;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px; 
  transition: box-shadow 0.3s ease, transform 0.3s ease; 
}

h3 em {
  font-style: italic; 
  opacity: 0.7; 
}

.project-image {
  width: 100%;
  height: auto;
}

.project-info {
  font-size: 12px; 
  padding: 15px;
}

.project-info h3 {
  margin-top: 0;
}

.project-link {
  display: inline-block;
  margin-top: 10px;
  color: #8649EF;
}

/* Glow and sacle effect on hover */
.project-card:hover {
  box-shadow: 0 0 15px #8649EF;
  transform: scale(1.05); 

}

/* Media query for larger screens */
@media (min-width: 768px) {
  .project-cards-container {
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap; 
    gap: 40px; 
    justify-content: center; 
    align-items: flex-start;
  }

  .project-card {
    width: 600px; 
  }

  
}


/* Experience Table Styles */

.experience-content h1 {
  margin-bottom: 20px; /* Space below the heading */
}

.experience-content table {
  border-collapse: collapse; /* Collapse borders */
  width: 100%; /* Full width */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth */
  font-size: 1.6rem;
  
}

.experience-content th, 
.experience-content td {
  border: 1px solid #ddd; /* Light grey border */
  padding: 8px; /* Spacing inside cells */
  text-align: left; /* Align text to the left */
}

.experience-content th {
  background-color: #8649EF; /* Example header background color */
  color: white; /* Text color for header */
}


/* Form Styles */
.contact-form form {
  font-size: 2rem;
  margin: 2%;
  padding: 2%;
}

.form-instructions {
  color: #8649EF;
}

/* Fieldset Styles */
fieldset {
  margin: 0 0 1em 0;
  border: none; 
}

.submitbutton {
  margin-bottom: 0;
  text-align: center;
}

/* Field Styles */
input, textarea {
  padding: 0.2em;
  font-size: 1em;
  width: 100%; 
  box-sizing: border-box; 
  margin-bottom: 0.5em; 
}


input:focus, textarea:focus {
  background-color: #8649EF;
  color: white;
}

/* Label Styles */
label {
  font-size: 1em;
  display: block; 
  margin-bottom: 0.5em; 
}

/* label styles */
label {
  font-size: 1em;
}
.contactinfo label {
  margin: 0.7em 0;
  display: block;
  position: relative;
}
.contactinfo label.required::before {
  content: "* ";
  color: #8649EF;
  position: absolute;
  left: -1em; 
}


@media (max-width: 1200px) {
  html {
      font-size: 55%;
  }
}

@media (max-width: 991px) {
  .sitenavigation {
      padding: 2rem 3%;
  }

  section {
      padding: 10rem 3% 2rem;
  }
}

@media (max-width: 768px) {
  #menu-icon {
      display: block;
  }
  
  .navbar {
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      padding: 1rem 3%;
      background: #0F0F13;
      display: none;
      z-index: 11;
  }

  .navbar.active {
      display: block;
  }

  .navbar a {
      display: block;
      font-size: 2rem;
      margin: 3rem 0;
  }

  .home {
      flex-direction: column;
  }

  .introduction h3 {
      font-size: 2.6rem;
  }

  .introduction h1 {
      font-size: 5rem;
  }

}

@media (max-width: 450px) {
  html {
      font-size: 50%;
  }
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.skill {
  flex-basis: 48%; /* For 2 skills per line on larger screens */
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 0 10px; /* Padding for spacing between skills */
}

.skill h2 {
  margin-bottom: 10px;
  font-size: 20px;
}

.progress {
  background-color: #e9ecef;
  border-radius: 5px;
  height: 15px;
  overflow: hidden;
  transition: all 0.4s ease; 
}

.progress:hover {
  height: 20px;
  box-shadow: 0 0 6px 2px #ffffff; 
}
.progress-bar:hover {
  height: 20px;
  box-shadow: 0 0 16px 2px #8649EF; 
}

.progress-bar {
  background-color: #8649EF;
  height: 100%;
  line-height: 15px;
  color: white;
  text-align: center;
  border-radius: 5px;
  transition: width 0.6s ease;
}


.container {
  width: 100%;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  grid-column-gap: 20px; /* Space between columns */
  z-index: 10;
}

main.row {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
}

.col .contents{
	padding: 15px 30px;
	border-left: 2px solid #8649EF;
}
.col .contents .box{
	position: relative;
	padding: 20px;
	border: 1px solid #141314;
	background-color: #0F0F13;
	cursor: pointer;
	transition: all 0.4s;
	margin-bottom: 20px;
}
.col .contents .box:hover{
	box-shadow: 0px 1px 5px 0px #8649EF;
	border: 1px solid transparent;
}
.col .contents .box::before{
	content: '';
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	right: calc(100% + 22px);
	top: 0;
	background-color: #8649EF;
	border: 2px solid #8649EF;
}
.box h4{
  font-size: 13px;
	position: relative;
	color: #8649EF;
}
.box h3{
	font-size: 19px;
	padding: 10px 0px 6px;
	font-family: helvetica;
	color: #ffffff;
}
.box p{
	line-height: 1.2;
	color: #8649EF;
	font-size: 17px;
}
/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .skill {
      flex-basis: 100%; /* Full width for 1 skill per line on smaller screens */
      padding: 0 10px; /* Maintain padding */
  }
}

@media(max-width: 900px) {
  .row {
      grid-template-columns: 1fr; /* Stacks the columns on smaller screens */
  }
  .col:nth-child(2) {
      margin-top: 30px;
  }
}