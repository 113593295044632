/* GridBackground.css */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  gap: 1.2px; /* This will apply to both rows and columns */
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed; /* Added to position the element */
  top: 0; /* Align to the top of the page */
  left: 0; /* Align to the left of the page */
  z-index: 5 /* High value to ensure it's on top */
}

.grid-cell {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  background-color: #0F0F13;
  transition: background-color 0.6s ease;
}

.grid-cell:hover {
  background-color: #fff; /* Color when the mouse hovers over a cell */
}

.grid-cell.hovered {
  background-color: #8649EF; /* Color when the cell or adjacent cells are hovered over */
}
